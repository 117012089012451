import React from "react";
import {observer} from "mobx-react";
import * as BP from "@blueprintjs/core";
import cn from 'classnames';
//
import {E5AngularGauge} from "../../../../global/plot/E5AngularGauge";
import {E5PieChart} from "../../../../global/plot/E5PieChart";
import {E5MainConfig} from "../../../../global/E5MainConfig";
import {E5CBSysClass, E5CBSysClassDetails, E5CBSysMet, E5CBSysMetPercentage, E5CBSysRebootReasonSource, E5StoreCBSys} from "../../../../store/E5StoreCBSys";
import {E5UtilI18n} from "../../../../global/E5MainLang";
import {E5StoreCB} from "../../../../store/E5StoreCB";
import {E5Store} from "../../../../store/E5Store";
import {E5CBDashboard, E5CBSysEqpType} from "../E5CBDashboard";
//
import "./E5CBSysReboot.css";
import { E5XYChart, E5XYNumData, E5XYSource } from "../../../../global/plot/E5XYChart";

//E5
interface E5CBSysRebootState {
	selectedFilterRebootReasonGw: string | null;
	selectedFilterRebootReasonExt: string | null;
	graphDataRebootReasonGw: E5XYSource;
	graphDataRebootReasonExt: E5XYSource;
	gwRebootsReasonActive: string;
	extRebootsReasonActive: string;
}

//E5
interface E5CBSysRebootProps {
	toasterref: React.RefObject<BP.Toaster>;
	downloadref: React.RefObject<BP.Button>;
	percent: boolean;
	togglefunc: () => void;
}

//E5
export const E5CBSysReboot = observer(class E5CBSysReboot extends React.PureComponent
	<E5CBSysRebootProps, E5CBSysRebootState> {
	constructor(props: E5CBSysRebootProps) {
		super(props);
		this.state = {
			selectedFilterRebootReasonGw: "All",
			selectedFilterRebootReasonExt: "All",
			graphDataRebootReasonGw: E5CBSysReboot.GetRebootReasonData(E5StoreCBSys.Ins().reboot.rebreasongateway, "All"),
			graphDataRebootReasonExt: E5CBSysReboot.GetRebootReasonData(E5StoreCBSys.Ins().reboot.rebreasonextender, "All"),
			gwRebootsReasonActive: 'reason',
			extRebootsReasonActive: 'reason',
			
		};
	}	

    FilterGwRebootReason = (item: any) => {
		let graphDataRebootReasonGw;
		if (this.state.gwRebootsReasonActive === 'source') {
        	graphDataRebootReasonGw = E5CBSysReboot.GetRebootReasonData(E5StoreCBSys.Ins().reboot.rebsourcegateway, item);
		} else {
        	graphDataRebootReasonGw = E5CBSysReboot.GetRebootReasonData(E5StoreCBSys.Ins().reboot.rebreasongateway, item);
		}
        this.setState({ graphDataRebootReasonGw });
    };

    FilterExtRebootReason = (item: any) => {
		let graphDataRebootReasonExt;
		if (this.state.extRebootsReasonActive === 'source') {
        	graphDataRebootReasonExt = E5CBSysReboot.GetRebootReasonData(E5StoreCBSys.Ins().reboot.rebsourceextender, item);
		} else {
        	graphDataRebootReasonExt = E5CBSysReboot.GetRebootReasonData(E5StoreCBSys.Ins().reboot.rebreasonextender, item);
		}
        this.setState({ graphDataRebootReasonExt });
    };

    handleItemClick = (item: any, type: string) => {
		if (type === 'gw') {
			this.setState({ selectedFilterRebootReasonGw: item }, () => {
				this.FilterGwRebootReason(item);
			});
		} else {
			this.setState({ selectedFilterRebootReasonExt: item }, () => {
				this.FilterExtRebootReason(item);
			});
		}
    };

	updateGwRebootsActive = () => {
		this.setState({ gwRebootsReasonActive: this.state.gwRebootsReasonActive === 'source' ? 'reason' : 'source' }, () => {
			this.FilterGwRebootReason(this.state.selectedFilterRebootReasonGw);
		});
	}


	updateExtRebootsActive = () => {
		this.setState({ extRebootsReasonActive: this.state.extRebootsReasonActive === 'source' ? 'reason' : 'source' }, () => {
			this.FilterExtRebootReason(this.state.selectedFilterRebootReasonExt);
		});
	}

	// ---------------- RENDER ----------------

	//E5
	render(): JSX.Element {
		// force rerender when lang changes
		let curlang = E5Store.Ins().langinfo.curlang; //eslint-disable-line
		let {_} = E5UtilI18n, {status, health, rebequips, rebdetails, uptequips, uptdetails, rebmetrics, rebextender, rebgateway, rebpercentagemetrics, rebreasongateway, rebsourcegateway, rebreasonextender, rebsourceextender} =  //eslint-disable-line
				E5StoreCBSys.Ins().reboot, {loading} = status, {nilistsettings} = E5StoreCB.Ins(),
			{mode, cla, eqptype, sofv, model} = nilistsettings, {  //eslint-disable-line
				RenderPiePopover, DownloadNiListEquip, RenderEquipsPie, PieClickEquip1, PieClickEquip2,  //eslint-disable-line
				DownloadNiListDetail, RenderDetailsPie, PieClickDetail1, PieClickDetail2, RenderChart  //eslint-disable-line
			} = E5CBDashboard;

		let [ids, parents, values, labels] = E5CBSysReboot.GetRebootPieData();
		let [ids2, parents2, values2, labels2] = E5CBSysReboot.GetRebootModelVersionData(rebgateway);
		let [ids3, parents3, values3, labels3] = E5CBSysReboot.GetRebootModelVersionData(rebextender);
		let filterRebootReasonGw: string[] = E5CBSysReboot.GetFilterRebootReason(rebreasongateway);
		let filterRebootReasonExt: string[] = E5CBSysReboot.GetFilterRebootReason(rebreasonextender);
		const { selectedFilterRebootReasonGw, selectedFilterRebootReasonExt, graphDataRebootReasonGw, graphDataRebootReasonExt, gwRebootsReasonActive, extRebootsReasonActive } = this.state;

		const customFormatterReboot = (params: any) => {
			let header = params[0].axisValueLabel;
	
			let result = `<div style="text-align: center; margin-bottom: 5px;">
							${header}
						  </div>`;
	
			params.forEach((item: any) => {
				let yValue = item.value[1];
				let percentage = item.value[2];
				let color = item.color;
	
				result += `
					<div style="display: flex; align-items: center; justify-content: space-between; gap: 20px;">
						<div style="display: flex; align-items: center;">
							<span style="display: inline-block; width: 10px; height: 10px; 
								background-color: ${color}; border-radius: 50%; margin-right: 5px;"></span>
							<span>${item.seriesName}</span>
						</div>
						<div><strong>${yValue}</strong> (${percentage}%)</div>
					</div>
				`;
			});
	
			return result;
		}

		return <div className="e5cb-sys-reboot e5column-20">
			<div className="e5line-20">
				<div className="e5linefull">
					<E5AngularGauge
						gaugeinfo={{value: health, label: _("system-reb"), title: E5UtilI18n._("cb-sys-reboot-health-score"), loading}} isNewComponent chartOption={{type:'gauge'}}/>
				</div>
				<div className="e5linefull">
					<E5PieChart pieinfo={{
						title: E5UtilI18n._("cb-sys-reboot-piereb"), roundvalues: true,
						subtitle: E5UtilI18n._("cb-sys-reboot-pie-reason-sub"), loading,
						valueisseconds: false, labelisincident: false, ids, parents, values, labels
					}} withNewComponent/>
				</div>
			</div>
			<div className="e5line-20">
				<div className="e5linefull2">
					<E5PieChart pieinfo={{
						title: E5UtilI18n._("cb-sys-reboot-piereb"), loading, valueisseconds: false,
						subtitle: E5UtilI18n._("cb-sys-pie-gw-sub"), ids: ids2, parents: parents2, values: values2, labels: labels2, labelisincident: false,
						roundvalues: true, valsuffix: E5UtilI18n._("cb-sys-pie-val-suffix")
					}} chartOption={{ type: 'sunburst', colors: ['#D81B60', '#EC407A'] }} withNewComponent />
				</div>
				<div className="e5linefull2">
					<E5PieChart pieinfo={{
						title: E5UtilI18n._("cb-sys-reboot-piereb"), loading, valueisseconds: false,
						subtitle: E5UtilI18n._("cb-sys-pie-ext-sub"), ids: ids3, parents: parents3, values: values3, labels: labels3, labelisincident: false,
						roundvalues: true, valsuffix: E5UtilI18n._("cb-sys-pie-val-suffix")
					}} chartOption={{ type: 'sunburst', colors: ['#F4511E', '#FF7043'] }} withNewComponent />
				</div>
			</div>
			<div className="e5line-20">
				<div className="e5linefull2">
					<E5XYChart
						leftsource={E5CBSysReboot.GetIncidentsPercents(rebpercentagemetrics, 'GW')} rightsource={{}}
						xoptions={{ xisdaytime: false, xisday: false, xtimezone: 0, holesizesec: 0 }}
						title={_("cb-sys-reboot-percentage-gateways")}
						loading={loading} height={350} width={500} withNewComponent
						chartOption={{ filled: false, colors: ['#AED581', '#7EE8F6', '#F472B6'], gridPorcent: "15%", tollboxPorcentRight: "15%", legendRight: 10, legendTop: 20, legendOrient: "vertical"}} />
				</div>
			</div>
			<div className="e5line-20">
				<div className="e5linefull2">
					<E5XYChart
						leftsource={E5CBSysReboot.GetNumberReboots(rebmetrics, 'gw', this.props.percent)} rightsource={{}}
						xoptions={{ xisdaytime: false, xisday: false, xtimezone: 0, holesizesec: 0 }} 
						title={_("cb-sys-reboot-gwreb")} formatterTooltip={customFormatterReboot}
						loading={loading} height={350} withNewComponent
						chartOption={{ filled: false, colors: ["#4CC9F0", "#4361EE", "#7209B7", "#F83E92"], type: 'bar'}} />
				</div>
			</div>
			<div className="e5-bg-white">
				<div className="e5hactivity-box">
					<span className="e5hactivity-graph-title">{E5UtilI18n._("cb-sys-reboot-reason-over-time-gateways")}</span>
						<div className="e5line-20 device-type-filter">
							<div className="e5line-0 toggle">
								<div className={cn('toggle-item', { 'active': gwRebootsReasonActive === 'source' })} onClick={this.updateGwRebootsActive}>{E5UtilI18n._("cb-sys-reboot-source")}</div>
								<div className={cn('toggle-item', { 'active': gwRebootsReasonActive === 'reason' })} onClick={this.updateGwRebootsActive}>{E5UtilI18n._("cb-sys-reboot-reason")}</div>
							</div>
						</div>
				</div>
				<div className="e5line-20">
					<div className="e5linefull6">
						<E5XYChart
							leftsource={graphDataRebootReasonGw} rightsource={{}}
							xoptions={{ xisdaytime: false, xisday: false, xtimezone: 0, holesizesec: 0 }}
							loading={loading} height={350} width={600} withNewComponent
							chartOption={{ filled: false, type: 'bar', colors: ['#AED581', '#7EE8F6', '#F472B6', "#FED86E", "#DCE775"]}}
							/>
					</div>

					<div className="e5linefull">
						<div className="reboot-reason-list">
							<ul>
								{filterRebootReasonGw.map((item, index) => (
									<li key={index} onClick={() => this.handleItemClick(item, 'gw')} className={item === selectedFilterRebootReasonGw ? "active" : ""}>
										{item}
									</li>
								))}
							</ul>
						</div>
					</div>
				</div>
			</div>
			<div className="e5line-20">
				<div className="e5linefull2">
					<E5XYChart
						leftsource={E5CBSysReboot.GetIncidentsPercents(rebpercentagemetrics, 'EXT')} rightsource={{}}
						xoptions={{ xisdaytime: false, xisday: false, xtimezone: 0, holesizesec: 0 }}
						title={_("cb-sys-reboot-percentage-extenders")}
						loading={loading} height={350} width={500} withNewComponent
						chartOption={{ filled: false, colors: ['#AED581', '#7EE8F6', '#F472B6'], gridPorcent: "15%", tollboxPorcentRight: "15%", legendRight: 10, legendTop: 20, legendOrient: "vertical"}} />
				</div>
			</div>
			<div className="e5line-20">
				<div className="e5linefull2">
					<E5XYChart
						leftsource={E5CBSysReboot.GetNumberReboots(rebmetrics, 'ext', this.props.percent)} rightsource={{}}
						xoptions={{ xisdaytime: false, xisday: false, xtimezone: 0, holesizesec: 0 }} 
						title={_("cb-sys-reboot-extreb")} formatterTooltip={customFormatterReboot}
						loading={loading} height={350} withNewComponent
						chartOption={{ filled: false, colors: ["#4CC9F0", "#4361EE", "#7209B7", "#F83E92"], type: 'bar'}} />
				</div>
			</div>
			<div className="e5-bg-white">
				<div className="e5hactivity-box">
					<span className="e5hactivity-graph-title">{E5UtilI18n._("cb-sys-reboot-reason-over-time-extenders")}</span>
						<div className="e5line-20 device-type-filter">
							<div className="e5line-0 toggle">
								<div className={cn('toggle-item', { 'active': extRebootsReasonActive === 'source' })} onClick={this.updateExtRebootsActive}>{E5UtilI18n._("cb-sys-reboot-source")}</div>
								<div className={cn('toggle-item', { 'active': extRebootsReasonActive === 'reason' })} onClick={this.updateExtRebootsActive}>{E5UtilI18n._("cb-sys-reboot-reason")}</div>
							</div>
						</div>
				</div>
				<div className="e5line-20">
					<div className="e5linefull6">
						<E5XYChart
							leftsource={graphDataRebootReasonExt} rightsource={{}}
							xoptions={{ xisdaytime: false, xisday: false, xtimezone: 0, holesizesec: 0 }}
							loading={loading} height={350} width={600} withNewComponent
							chartOption={{ filled: false, type: 'bar', colors: ['#AED581', '#7EE8F6', '#F472B6', "#FED86E", "#DCE775"]}}
							/>
					</div>

					<div className="e5linefull">
						<div className="reboot-reason-list">
							<ul>
								{filterRebootReasonExt.map((item, index) => (
									<li key={index} onClick={() => this.handleItemClick(item, 'ext')} className={item === selectedFilterRebootReasonExt ? "active" : ""}>
										{item}
									</li>
								))}
							</ul>
						</div>
					</div>
					
				</div>
			</div>
		</div>;
	}

	// ---------------- UTILS ----------------

	//E5
	static GetRebootPieData: () => [string[], string[], number[], string[]] =
		(): [string[], string[], number[], string[]] => {
			let {reasonmap} = E5StoreCBSys.Ins().reboot,
				ids: string[] = [], parents: string[] = [], values: number[] = [], labels: string[] = [];
			if (reasonmap === null) {
				ids = ["N/A"];
				parents = [""];
				values = [0];
				labels = ["N/A"];
			} else if (reasonmap !== undefined) {
				ids = ["total"];
				parents = [""];
				values = [0];
				labels = [E5UtilI18n._("total")];
				for (let [reason, count] of reasonmap) {
					ids.push(reason);
					parents.push("total");
					values.push(count);
					labels.push(reason);
				}
			}
			return [ids, parents, values, labels];
		};

	static GetRebootModelVersionData: (data: E5CBSysClassDetails[] | null) => [string[], string[], number[], string[]] =
		(data: E5CBSysClassDetails[] | null): [string[], string[], number[], string[]] => {
			let ids: string[] = [], parents: string[] = [], values: number[] = [], labels: string[] = [];
			if (data === null) {
				ids = ["N/A"];
				parents = [""];
				values = [0];
				labels = ["N/A"];
			} else if (data !== undefined) {
				ids = ["total"];
				parents = [""];
				values = [0];
				labels = [E5UtilI18n._("total")];
				let modelCount: { [key: string]: number } = {};
				let sofvCount: { [key: string]: number } = {};
				for (let { model, sofv } of data) {
					model = model || "N/A";
					sofv = sofv || "N/A";
					if (modelCount[model] == undefined) {
						modelCount[model] = 0;
						ids.push(model);
						parents.push("total");
						values.push(0);
						labels.push(model);
					}
					let sofvKey = `${model};${sofv}`;
					if (!sofvCount[sofvKey]) {
						sofvCount[sofvKey] = 0;
						ids.push(sofvKey);
						parents.push(model);
						values.push(0);
						labels.push(sofv);
					}
					sofvCount[sofvKey]++;
				}
				for (let i = 0; i < ids.length; i++) {
					if (parents[i] === "total") {
						values[i] = modelCount[ids[i]];
					} else if (parents[i] === "") {
						values[i] = 0;
					} else {
						values[i] = sofvCount[ids[i]];
					}
				}
			}
			return [ids, parents, values, labels];
		};

		//E5
	static GetIncidentsPercents: (percentsMetrics: E5CBSysMetPercentage[] | null, filter: string) => E5XYSource =
		(percentsMetrics: E5CBSysMetPercentage[] | null, filter: string): E5XYSource => {
			if (!percentsMetrics) return { numdatas: [], options: { stacked: false, markers: true, rangemode: "tozero" } };

			let incidents: E5XYSource = { numdatas: undefined, options: { stacked: false, markers: true, rangemode: "tozero" } },
				metrics: any = {};
			incidents.numdatas = [];

			percentsMetrics.forEach((metric, i) => {
				metric.percentageModelSoftware.forEach((percent, j) => {
					if (percent.nodeType === filter) {
						if (metrics[percent.softwareVersion] === undefined) {
							metrics[percent.softwareVersion] = {
								xaxisdata: [],
								yaxisdata: [],
								datalabel: percent.softwareVersion,
							};
						}
						metrics[percent.softwareVersion].xaxisdata.push(metric.date);
						metrics[percent.softwareVersion].yaxisdata.push(percent.percentage);
					}
				});
			});

			incidents.numdatas = Object.values(metrics);

			return incidents;
		};
	
	static GetNumberReboots: (metrics: E5CBSysMet[] | null, filter: E5CBSysEqpType, percent: boolean) => E5XYSource =
		(metrics: E5CBSysMet[] | null, filter: E5CBSysEqpType, percent: boolean): E5XYSource => {
			let src: E5XYSource = { numdatas: undefined, options: { stacked: true, percent, markers: true } };
			src.numdatas = [];
			let met: E5CBSysMet, metelem: E5CBSysClass, classmap: Map<number, E5XYNumData> = new Map(),
			namestr: string = filter === "gw" ? "GWs" : filter === "ext" ? "EXTs" : "STBs",
			numdata: E5XYNumData | undefined, rank: number;

			metrics?.forEach((met) => {
				const totalReboots = E5CBSysReboot.GetTotalReboot(met[filter]);
				met[filter]?.forEach((metelem) => {
					rank = metelem.rank;
					numdata = classmap.get(rank);

					if (numdata === undefined) {
						numdata = { xaxisdata: [], yaxisdata: [], datalabel: metelem.class, tooltip: [] };
						classmap.set(rank, numdata);
					}
					numdata.xaxisdata.push(met.date);
					numdata.yaxisdata.push(metelem.count);
					numdata?.tooltip?.push(((metelem.count / totalReboots) * 100).toFixed(2));
				});
			});

			classmap.forEach((numdata, rank) => {
				numdata.fillcolor = E5CBDashboard.GetGradientColor(E5MainConfig.GetClassColorSteps(),
					100 / (classmap.size - 1) * rank);
				src.numdatas?.push(numdata);
			});

			return src;
		};

	static GetTotalReboot: (data: E5CBSysClass[] | null) => number =
		(data: E5CBSysClass[] | null): number => {
			let totalReboots = 0;
			data?.forEach((classData) => {
				totalReboots += classData.count;
			});
			return totalReboots;
		};

	static GetRebootReasonData: (data: E5CBSysRebootReasonSource[] | null, filter: string) => E5XYSource =
		(data: E5CBSysRebootReasonSource[] | null, filter: string): E5XYSource => {
			if (!data) return { numdatas: [], options: { stacked: false, markers: true, rangemode: "tozero" } };

			let rebootReason: E5XYSource = { numdatas: undefined, options: { stacked: false, markers: true, rangemode: "tozero" } };
			const rebootReasonData: any = {};

			const filteredData = filter?.toUpperCase() === "ALL" ? data : data?.filter((reason) => reason.sofv?.toUpperCase() === filter?.toUpperCase());
			const allDates = new Set();
			const reboots = new Set();

			filteredData.forEach((reason) => {
				allDates.add(reason.date);
				reboots.add(reason.reboot);
			});

			reboots.forEach((reboot: any) => {
				if ( reboot === null ) return;
				if (!rebootReasonData[reboot]) {
					rebootReasonData[reboot] = {
						datalabel: reboot,
						xaxisdata: Array.from(allDates),
						yaxisdata: Array.from(allDates).map(() => 0),
					};
				}
			});	

			filteredData.forEach((reason) => {
				const { date, count, reboot} = reason;
				if ( reboot === null ) return;
				const dateIndex = rebootReasonData[reboot].xaxisdata.indexOf(date);
				rebootReasonData[reboot].yaxisdata[dateIndex] += count;
			});

			rebootReason.numdatas = Object.values(rebootReasonData);
			return rebootReason;
		};

	static GetFilterRebootReason(data: E5CBSysRebootReasonSource[] | null): string[] {
		if (!data) return ["All"];

		const allReasons = new Set();
		data.forEach((reason) => {
			allReasons.add(reason.sofv);
		});
		return ["All", ...Array.from(allReasons)] as string[];
	}
});
