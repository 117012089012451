import React, { useState } from "react";
import * as BP from "@blueprintjs/core";
import Table from '../../../global/component/E5Table'
import { E5UtilI18n } from "../../../global/E5MainLang";
import { E5EntAclGroup } from "../../../entity/E5EntAclGroup";
import "./DownloadUsersModal.css";
import Button from "../../../global/component/Button";
import { E5EntUser } from "../../../entity/E5EntUser";
type componentProps = {
    users: E5EntUser[],
    downloadUsers: Function,
    onClose: Function,
};

const DownloadUsersModal = ({ users, downloadUsers, onClose }: componentProps) => {
    return <div>
        <BP.Dialog isOpen={true} canOutsideClickClose={false} canEscapeKeyClose={false} className="download-users-dialog"
            onClose={onClose}
            title={E5UtilI18n._("admin-users-right-review")}>
            <Table
                columns={[
                    {
                        field: 'lastname',
                        headerName: E5UtilI18n._('admin-users-lastname'),
                        editable: true,
                        headerAlign: 'center',
                        align: 'left',
                        minWidth: 100,
                        renderCell: (params) => {
                            return params.row.lastname;
                        },
                    },
                    {
                        field: 'firstname',
                        headerName: E5UtilI18n._('admin-users-firstname'),
                        editable: true,
                        headerAlign: 'center',
                        align: 'left',
                        with: 20,
                        minWidth: 100,
                        renderCell: (params) => {
                            return params.row.firstname;
                        },
                    },
                    {
                        field: 'username',
                        headerName: E5UtilI18n._('admin-users-username'),
                        editable: true,
                        headerAlign: 'center',
                        align: 'left',
                        minWidth: 100,
                        renderCell: (params) => {
                            return params.row.username;
                        },
                    },
                    {
                        field: 'email',
                        headerName: E5UtilI18n._('admin-users-email'),
                        editable: true,
                        headerAlign: 'center',
                        align: 'left',
                        minWidth: 200,
                        renderCell: (params) => {
                            return params.row.email;
                        },
                    },
                    {
                        field: 'company',
                        headerName: E5UtilI18n._('admin-users-company'),
                        editable: true,
                        headerAlign: 'center',
                        align: 'left',
                        minWidth: 100,
                        renderCell: (params) => {
                            return params.row.company;
                        },
                    },
                    {
                        field: 'jobTitle',
                        headerName: E5UtilI18n._('admin-users-jobtitle'),
                        editable: true,
                        headerAlign: 'center',
                        align: 'left',
                        minWidth: 100,
                        renderCell: (params) => {
                            return params.row.jobtitle;
                        },
                    },
                    {
                        field: 'status',
                        headerName: E5UtilI18n._('admin-users-status'),
                        editable: true,
                        headerAlign: 'center',
                        align: 'center',
                        minWidth: 80,
                        renderCell: (params) => {
                            return params.row.accountnonlocked === true ? <img src='./img/v3/connectivity/account-unlocked.svg' alt="Account unlocked" /> : <img src='./img/v3/connectivity/account-locked.svg' alt="Accountlocked" />
                        },
                    },
                    {
                        field: 'roles',
                        headerName: E5UtilI18n._('admin-users-roles'),
                        editable: true,
                        headerAlign: 'center',
                        align: 'left',
                        minWidth: 200,
                        renderCell: (params) => {
                            const arrayOfRoles = params.row.roles.split(', ');
                            return <div>{arrayOfRoles.map((item) => {
                                if (item === 'Analyst') {
                                    return <span style={{ backgroundColor: '#ede6f5', color: '#9031be' }} className="role-in-table">{item}</span>
                                }
                                if (item === 'Manager') {
                                    return <span style={{ backgroundColor: '#ebeafa', color: '#5348d2' }} className="role-in-table">{item}</span>
                                }
                                if (item === 'Sup. L1') {
                                    return <span style={{ backgroundColor: '#eaf1e2', color: '#6aa609' }} className="role-in-table">{item}</span>
                                }
                                if (item === 'Sup. L2') {
                                    return <span style={{ backgroundColor: '#fff9ed', color: '#ffb94e' }} className="role-in-table">{item}</span>
                                }
                                if (item === 'Sup. L3') {
                                    return <span style={{ backgroundColor: '#fae9e4', color: '#fd5d18' }} className="role-in-table">{item}</span>
                                }
                                if (item === 'Administrator') {
                                    return <span style={{ backgroundColor: '#fae4e5', color: '#cb0909' }} className="role-in-table">{item}</span>
                                }
                                if (item === 'RDM') {
                                    return <span style={{ backgroundColor: '#dce3ef', color: '#3c87f8' }} className="role-in-table">{item}</span>
                                }
                            })}</div>
                        },
                    },
                    {
                        field: 'aclgroups',
                        headerName: E5UtilI18n._('admin-users-aclgroups'),
                        editable: true,
                        headerAlign: 'center',
                        align: 'left',
                        minWidth: 100,
                        renderCell: (params) => {
                            return params.row.aclgroups;
                        },
                    },
                    {
                        field: 'accountexpirationdate',
                        headerName: E5UtilI18n._('admin-users-accountexpirationdate'),
                        editable: true,
                        headerAlign: 'center',
                        align: 'left',
                        minWidth: 150,
                        renderCell: (params) => {
                            return params.row.accountexpirationdate || 'none';
                        },
                    }]}
                rows={
                    [...users.map((row, index) => {
                        return (
                            {
                                ...row,
                                id: row.email,
                                lastsuccessfullogin: row.lastsuccessfullogin?.format(E5UtilI18n._("dateformat") + " HH:mm"),
                                accountexpirationdate: row.accountexpirationdate?.format(E5UtilI18n._("dateformat")),
                                roles: row.roles.map(role => E5UtilI18n._("admin-users-role-" + role)).join(', '),
                                aclgroups: row.aclgroups.map((acl: E5EntAclGroup) => acl.name).join(", "),
                                status: row.accountnonexpired,
                            }
                        )
                    }

                    )]
                }
            />
            <div className='download-users-buttons-wrapper'>
                <Button theme="blue" onClick={() => downloadUsers(onClose)}>
                    {E5UtilI18n._("download")}
                </Button>
                <Button theme="blue" onClick={() => onClose()}>
                    {E5UtilI18n._("cancel")}
                </Button>
            </div>
        </BP.Dialog>
    </div>
};

export default DownloadUsersModal;