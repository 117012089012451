import { E5UtilI18n } from "../global/E5MainLang";

export function rebootChartOptions(data: any): any {
    const seriesData = data.map((item: any) => {
        return {
            value: [item.xaxis, item.yaxis, item.tooltipValues],
            itemStyle: {
                color: item.isFwUpdate ? '#e97132' : '#156082'
            },
            tooltip: {
                borderColor: item.isFwUpdate ? '#e97132' : '#156082',
                formatter(params) {
                    const tooltipValues = params.value[2];
                    const tooltip = Object.keys(tooltipValues).map((key, index) => {
                        if (index === 0) {
                            return `<span style="text-align: start"><b>${E5UtilI18n._(key)} - ${tooltipValues[key]}</b></span>`;
                        }
                        return `<span style="text-align: start">${E5UtilI18n._(key)} <b>${tooltipValues[key]}</b></span>`;
                    });
                    
                    return `<div style="display:flex; flex-direction: column; justify-content: flex-start">${tooltip.join('')}</div>`;
                }
            }
        }
    });

    return {
        tooltip: {
            trigger: 'item',
        },
        grid: {
            left: '2%',
            right: '2%',
            bottom: '15%',
            containLabel: true
        },
        toolbox: {
            right: '4%',
            feature: {
                dataView: {
                    show: true,
                    readOnly: true,
                    title: E5UtilI18n._("toolbox-dataView"),
                },
                dataZoom: {
                    yAxisIndex: 'none',
                    title: {
                        zoom: E5UtilI18n._("toolbox-datazoom-zoom"),
                        back: E5UtilI18n._("toolbox-datazoom-back")
                    },
                },
                magicType: {
                    show: false,
                },
                restore: {
                    show: true,
                    title: E5UtilI18n._("toolbox-restore"),
                },
                saveAsImage: {
                    title: E5UtilI18n._("toolbox-saveAsImage"),
                }
            }
        },
        xAxis: {
            type: 'time',
            boundaryGap: ['50%', '50%'],
        },
        yAxis: {
            type: 'value',
            boundaryGap: ['50%', '50%'],
            minInterval: 1,
            min: 0,
            axisLabel: {
                show: true,
            }
        },
        series: [
            {
                symbolSize: 15,
                data: seriesData,
                type: 'scatter'
            }
        ]
    }
}

export function cpuUsageAndLoadChartOptions(data: any): any {
    const legends: any[] = [];
    const seriesData = data?.map((item: any) => {
        legends.push(item.name);

        const value = item?.axis?.map((value: any) => {
            return [value.x, value.y];
        }) ?? [];
        
        return {
            name: item.name,
            data: value,
            type: 'line',
            itemStyle: { color: item.color},
            showSymbol: false
        }
    }) ?? [];

    return {
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'line',
                label: {
                    backgroundColor: '#6a7985'
                }
            },
        },
        grid: {
            left: '2%',
            right: '2%',
            bottom: '15%',
            containLabel: true
        },
        legend: {
            data: legends,
            bottom: 0,
        },
        toolbox: {
            right: '4%',
            feature: {
                dataView: {
                    show: true,
                    readOnly: true
                },
                dataZoom: {
                    yAxisIndex: 'none'
                },
                magicType: {
                    show: false,
                },
                restore: {
                    show: true,
                },
                saveAsImage: {}
            }
        },
        xAxis: {
            type: 'time',
            boundaryGap: ['50%', '50%'],
        },
        yAxis: {
            type: 'value',
            boundaryGap: ['50%', '50%'],
            minInterval: 1,
            min: 0,
        },
        series: seriesData
    }
}

export function uptimeChartOptions(data: any): any {
    const seriesData = data.map((item: any) => {
        return {
            value: [item.xaxis, item.yaxis, item.tooltipValues],
            itemStyle: {
                color: "#26C6DA"
            },
            tooltip: {
                borderColor: item.isFwUpdate ? '#e97132' : '#156082',
                formatter(params) {
                    const tooltipValues = params.value[2];
                    const tooltip = Object.keys(tooltipValues).map((key, index) => {
                        if (index === 0) {
                            return `<span style="text-align: start"><b>${E5UtilI18n._(key)} - ${tooltipValues[key]}</b></span>`;
                        }
                        return `<span style="text-align: start">${E5UtilI18n._(key)} <b>${tooltipValues[key]}</b></span>`;
                    });
                    
                    return `<div style="display:flex; flex-direction: column; justify-content: flex-start">${tooltip.join('')}</div>`;
                }
            },
            symbol: item.hasTolltip ? 'circle' : 'none',
        }
    });

    return {
        tooltip: {
            trigger: 'item',
        },
        color: ["#26C6DA"],
        grid: {
            left: '2%',
            right: '2%',
            bottom: '15%',
            containLabel: true
        },
        legend: {
            data: ['Uptime'],
            bottom: 0,
        },
        toolbox: {
            right: '4%',
            feature: {
                dataView: {
                    show: true,
                    readOnly: true,
                    title: E5UtilI18n._("toolbox-dataView"),
                },
                dataZoom: {
                    yAxisIndex: 'none',
                    title: {
                        zoom: E5UtilI18n._("toolbox-datazoom-zoom"),
                        back: E5UtilI18n._("toolbox-datazoom-back")
                    },
                },
                magicType: {
                    show: false,
                },
                restore: {
                    show: true,
                    title: E5UtilI18n._("toolbox-restore"),
                },
                saveAsImage: {
                    title: E5UtilI18n._("toolbox-saveAsImage"),
                }
            }
        },
        xAxis: {
            type: 'time',
            boundaryGap: ['50%', '50%'],
        },
        yAxis: {
            type: 'value',
            boundaryGap: ['50%', '50%'],
            minInterval: 1,
            min: 0,
            axisLabel: {
                show: true,
            }
        },
        series: [
            {
                name: 'Uptime',
                data: seriesData,
                type: 'line'
            }
        ]
    }
}

export function crashEventChartOptions(data: any): any {
    const seriesData = data[0]?.seriesData.map((item: any) => {
        return {
            value: [item.xaxis, item.yaxis, item.tooltipValues],
            itemStyle: {
                color: '#156082'
            },
            tooltip: {
                borderColor: '#156082',
                formatter(params) {
                    const tooltipValues = params.value[2];
                    const tooltip = Object.keys(tooltipValues).map((key, index) => {
                        if (index === 0) {
                            return `<span style="text-align: start"><b>${E5UtilI18n._(key)} - ${tooltipValues[key]}</b></span>`;
                        }
                        return `<span style="text-align: start"><b>${E5UtilI18n._(key)}</b> ${tooltipValues[key]}</span>`;
                    });
                    
                    return `<div style="display:flex; flex-direction: column; justify-content: flex-start">${tooltip.join('')}</div>`;
                }
            }
        }
    });

    return {
        tooltip: {
            trigger: 'item',
        },
        grid: {
            left: '2%',
            right: '2%',
            bottom: '15%',
            containLabel: true
        },
        toolbox: {
            right: '4%',
            feature: {
                dataView: {
                    show: true,
                    readOnly: true,
                    title: E5UtilI18n._("toolbox-dataView"),
                },
                dataZoom: {
                    yAxisIndex: 'none',
                    title: {
                        zoom: E5UtilI18n._("toolbox-datazoom-zoom"),
                        back: E5UtilI18n._("toolbox-datazoom-back")
                    },
                },
                magicType: {
                    show: false,
                },
                restore: {
                    show: true,
                    title: E5UtilI18n._("toolbox-restore"),
                },
                saveAsImage: {
                    title: E5UtilI18n._("toolbox-saveAsImage"),
                }
            }
        },
        xAxis: {
            type: 'time',
            boundaryGap: ['50%', '50%'],
        },
        yAxis: {
            type: 'value',
            boundaryGap: ['50%', '50%'],
            minInterval: 1,
            min: 0,
            axisLabel: {
                show: true,
            }
        },
        series: [
            {
                symbolSize: 15,
                data: seriesData,
                type: 'scatter'
            }
        ]
    }
}
