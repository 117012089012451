import React from "react";
import { observer } from "mobx-react";
import * as BP from "@blueprintjs/core";
//
import { E5StoreHTopoSelected, E5StoreHEquips, E5StoreHMetWifi, E5StoreH } from "../../../store/E5StoreH";
import { E5BandEnum, E5BandEnumIsEth, E5BandEnumIsWifi, E5NetElementType } from "../../../entity/E5Enums";
import { E5EntHMetWifi, E5HMetWifiField } from "../../../entity/household/metric/E5EntHMetWifi";
import { E5MetricCategData, E5MetricNumData } from "../../../global/plot/E5MetricChart";
import { E5PageTitle } from "../../../global/component/E5PageTitle";
import { E5MainConfig } from "../../../global/E5MainConfig";
import { E5HMetricWifiEquip } from "./E5HMetricWifiEquip";
import { E5HMetricWifiLink } from "./E5HMetricWifiLink";
import { E5HMetricSysEquip } from "./E5HMetricSysEquip";
import { E5HMetricWifiOverview } from "./E5HMetricWifiOverview";
import { E5HMetricProcess } from "./E5HMetricProcess";
import { E5UtilI18n } from "../../../global/E5MainLang";
import { E5Store } from "../../../store/E5Store";
import { E5HFilters } from "../E5HFilters";
//
import "./E5HMetric.css";
import { E5HMetricWan } from "./E5HMetricWan";
import { DEFAULT_COLORS } from "../../../util/E5DashboardHealthUtils";

enum MainTabIds {
	WiFi = 'tabid-wifi',
	WAN = 'tabid-wan',
	System = 'tabid-sys',
}

//E5
interface E5HMetricState {
	tabid?: string;
	mainTabId: string;
}

//E5
interface E5HMetricProps {
	location: any;
}

//E5
export const E5HMetric = observer(class E5HMetric extends React.PureComponent<E5HMetricProps, E5HMetricState> {
	static readonly airtimeProps: Array<string> = ['_idle', '_interference', '_recvother', '_recvself', '_sent'];
	
	constructor(props: E5HMetricProps, state: E5HMetricState) {
		super(props, state);
		this.state = {
			tabid: "wifi-overview",
			mainTabId: "tabid-wifi"
		};

	}
	// ---------------- RENDER ----------------
	componentDidMount() {
		const params = new URLSearchParams(this.props.location.search);
		const equip = params.get('equip');
		const page = params.get('page');
		if (page) {
			this.setState({ mainTabId: MainTabIds.WiFi, tabid: 'wifi-link' })
		}
		if (equip) {
			this.setState({ mainTabId: MainTabIds.WiFi, tabid: 'wifi-eqp' })
		}
	}
	//E5
	render(): JSX.Element {
		// force rerender when lang changes
		let curlang = E5Store.Ins().langinfo.curlang; //eslint-disable-line
		const params = new URLSearchParams(this.props.location.search);
		const station = params.get('station');
		const equip = params.get('equip');
		return <div className="e5page e5wifih-metrics e5columnfull e5column-20">
			<div className="e5column-5">
				{/* <BP.Breadcrumbs items={[
					{ icon: "bookmark", text: E5UtilI18n._("mainwifititle") },
					{ text: E5UtilI18n._("mainmenu-household") }, { text: E5UtilI18n._("mainmenu-h-metrics") }
				]} /> */}
				<E5PageTitle titlekey="pagetitle-h-metrics" />
			</div>
			<E5HFilters langinfo={E5Store.Ins().langinfo} searchniinfo={E5StoreH.Ins().searchniinfo}
				searchniautoinfo={E5StoreH.Ins().searchniautoinfo} pageinfo={E5Store.Ins().pageinfo} />
			<BP.Tabs className="e5columnfull" renderActiveTabPanelOnly defaultSelectedTabId={equip ? "wifi-link" : "wifi-eqp"}
				onChange={this.OnChangeTab} selectedTabId={this.state.tabid}>

				<BP.Tab className={`e5column-0 initial-tab ${this.state.mainTabId === MainTabIds.WiFi ? 'active-tab' : ""}`} id="tabid-wifi" title={'Wi-Fi'} />
				<BP.Tab className={`e5column-0 initial-tab ${this.state.mainTabId === MainTabIds.WAN ? 'active-tab' : ""}`} id="tabid-wan" title={'WAN'}
					panel={<E5HMetricWan wanInfo={E5StoreH.Ins().indicwaninfo} />}
				/>
				<BP.Tab className={`e5column-0 initial-tab ${this.state.mainTabId === MainTabIds.System ? 'active-tab' : ""}`} id="tabid-sys" title={E5UtilI18n._("h-equip-system")} />
				{E5MainConfig.GetWifiEnabled() && this.state.tabid?.includes('wifi-') &&
					<BP.Tab className="wifi-tab wifi-tab-with-margin" id="wifi-overview" title={E5UtilI18n._("wifih-metrics-wifi-overview")} role='tab'
						panel={<E5HMetricWifiOverview langinfo={E5Store.Ins().langinfo} searchniinfo={E5StoreH.Ins().searchniinfo}
							wifiinfo={E5StoreH.Ins().indicwifiinfo} />} />
				}
				{E5MainConfig.GetWifiEnabled() && this.state.tabid?.includes('wifi-') &&
					<BP.Tab className="wifi-tab wifi-tab-with-margin" id="wifi-eqp" title={E5UtilI18n._("wifih-metrics-wifi-eqp-tab")} role='tab'
						panel={<E5HMetricWifiEquip
							langinfo={E5Store.Ins().langinfo} metricinfo={E5StoreH.Ins().metwifiinfo}
							equipinfo={E5StoreH.Ins().equipinfo} selectedinfo={E5StoreH.Ins().selectedinfo} />} />
				}

				{E5MainConfig.GetWifiEnabled() && this.state.tabid?.includes('wifi-') &&
					<BP.Tab className="wifi-tab wifi-tab-full-width wifi-tab-with-margin" id="wifi-link" title={E5UtilI18n._("wifih-metrics-wifi-link-tab")} role='tab'
						panel={<E5HMetricWifiLink
							langinfo={E5Store.Ins().langinfo} stationinfo={E5StoreH.Ins().stationinfo}
							equipinfo={E5StoreH.Ins().equipinfo} selectedinfo={E5StoreH.Ins().selectedinfo}
							metricinfo={E5StoreH.Ins().metwifiinfo} station={station} />} />
				}

				{E5MainConfig.GetWifiEnabled() && this.state.tabid?.includes('sys-') &&
					<BP.Tab className="e5column-0 sys-tab first" id="sys-eqp" title={E5UtilI18n._("wifih-metrics-overview-tab")}
						panel={<E5HMetricSysEquip
							langinfo={E5Store.Ins().langinfo} selectedinfo={E5StoreH.Ins().selectedinfo}
							equipinfo={E5StoreH.Ins().equipinfo} metricinfo={E5StoreH.Ins().metsysinfo} indicsysinfo={E5StoreH.Ins().indicsysinfo} />} />}
				{E5MainConfig.GetWifiEnabled() && this.state.tabid?.includes('sys-') &&
					<BP.Tab className="e5column-0 sys-tab" id="sys-process" title={E5UtilI18n._("wifih-metrics-process-tab")}
						panel={<E5HMetricProcess selectedinfo={E5StoreH.Ins().selectedinfo}
							equipinfo={E5StoreH.Ins().equipinfo} metricinfo={E5StoreH.Ins().metprocessinfo} />} />
				}

			</BP.Tabs>
		</div>;
	}

	// ---------------- UTILS ----------------


	OnChangeTab = (newtabid: string): void => {
		if (newtabid === MainTabIds.WiFi) {
			this.setState({ mainTabId: MainTabIds.WiFi })
		}
		if (newtabid === MainTabIds.WAN) {
			this.setState({ mainTabId: MainTabIds.WAN })
		}
		if (newtabid === MainTabIds.System) {
			this.setState({ mainTabId: MainTabIds.System })
		}
		let tabid: string = newtabid === "tabid-wifi" ? "wifi-overview" : newtabid === 'tabid-sys' ? 'sys-eqp' : newtabid;

		this.setState({ tabid });
	};

	//E5
	static BuildPieData(filteronparent: boolean, selectedinterface: string, selectedinfo: E5StoreHTopoSelected,
		metricinfo: E5StoreHMetWifi, st: any): void {
		st.pieids = [];
		st.pieparents = [];
		st.pievalues = [];
		st.pielabels = [];

		if (selectedinfo.type !== E5NetElementType.none) {
			st.pieids = ["WiFi"];
			st.pieparents = [""];
			st.pievalues = [0];
			st.pielabels = ["WiFi"];

			let bandwidthmap: Map<string, number> = new Map(), sum: number | undefined,
				bandset: Set<string> = new Set(), bandstdset: Set<string> = new Set();

			for (let met of metricinfo.metrics) {
				if (E5BandEnumIsEth(met.wifiband) || met.wifiband === E5BandEnum.none) continue;
				if (filteronparent && selectedinterface !== met.parentinterfmac) continue;
				if (!filteronparent &&
					(selectedinfo.type !== E5NetElementType.device || met.devicemac !== selectedinfo.station.macaddress)
					&& (selectedinfo.type !== E5NetElementType.node || met.nodeimei !== selectedinfo.equip.imei))
					continue;

				let band: string = met.wifiband, standard: string = met.wifistandard,
					bandwidth: string = met.wifibandwidth, bandstd: string = band + ":" + standard;

				if (bandwidth === "") bandwidth = "N/A";
				if (standard === "") standard = "N/A";
				if (band === "") band = "?band";
				let key = band + ":" + standard + ":" + bandwidth;

				if (!bandstdset.has(bandstd)) {
					bandstdset.add(bandstd);

					if (!bandset.has(band)) {
						bandset.add(band);
						st.pieids.push(band);
						st.pielabels.push(band);
						st.pieparents.push("WiFi");
						st.pievalues.push(0);
					}
					st.pieids.push(bandstd);
					st.pieparents.push(band);
					st.pielabels.push(standard);
					st.pievalues.push(0);
				}
				sum = bandwidthmap.get(key);
				if (sum === undefined) sum = 0;
				sum += met.duration;
				bandwidthmap.set(key, sum);
			}

			bandwidthmap.forEach((duration: number, key: string) => {
				let [band, standard, bandwidth] = key.split(":");
				st.pieids.push(key);
				st.pieparents.push(band + ":" + standard);
				st.pievalues.push(duration);
				st.pielabels.push(bandwidth);
			});
		}
	}

	//E5
	static BuildAverageData(filteronparent: boolean, selectedinterface: string, selectedinfo: E5StoreHTopoSelected,
		metricinfo: E5StoreHMetWifi, st: any): void {
		// filtrer les données par l'équipement ou la station recherchée
		// sommer duration pour un même connectionid (séparer eth/wifi)
		// calculer la moyenne de duration sur l'ensemble des connectionid
		st.mindiceth = undefined;
		st.mindicwifi = undefined;
		let cnt: number, sum: number, val: number | undefined,
			ethduration: Map<string, number> = new Map(), wifiduration: Map<string, number> = new Map();
		for (let met of metricinfo.metrics) {
			if (filteronparent && selectedinterface !== met.parentinterfmac) continue;
			if (!filteronparent &&
				(selectedinfo.type !== E5NetElementType.device || met.devicemac !== selectedinfo.station.macaddress)
				&& (selectedinfo.type !== E5NetElementType.node || met.nodeimei !== selectedinfo.equip.imei))
				continue;
			if (E5BandEnumIsEth(met.wifiband)) {
				val = ethduration.get(met.connectionid);
				if (val === undefined) val = 0;
				val += met.duration;
				ethduration.set(met.connectionid, val);
			} else if (E5BandEnumIsWifi(met.wifiband)) {
				val = wifiduration.get(met.connectionid);
				if (val === undefined) val = 0;
				val += met.duration;
				wifiduration.set(met.connectionid, val);
			}
		}
		sum = 0;
		cnt = 0;
		ethduration.forEach((duration: number) => {
			cnt++;
			sum += duration;
		});
		if (cnt > 0) st.mindiceth = sum / cnt;
		sum = 0;
		cnt = 0;
		wifiduration.forEach((duration: number) => {
			cnt++;
			sum += duration;
		});
		if (cnt > 0) st.mindicwifi = sum / cnt;
	}

	static getAirtimeChartColor(group: string): string {
		switch (group) {
			case "_idle": 
				return DEFAULT_COLORS[0];
			case "_interference": 
				return DEFAULT_COLORS[3];
			case "_recvother": 
				return DEFAULT_COLORS[2];
			case "_recvself": 
				return DEFAULT_COLORS[1];
			case "_sent": 
				return DEFAULT_COLORS[4];
			default:
				return "";
		}
	}

	//E5
	static BuildGraphData(filteronparent: boolean, state: any, selectedinterface: string,
		selectedinfo: E5StoreHTopoSelected, equipinfo: E5StoreHEquips, metricinfo: E5StoreHMetWifi, st: any): void {
		let sides: string[] = ["left", "right"], xydata: any = { left: [], right: [] },
			nodesssidmap: Map<string, string> = new Map();

		for (let equip of equipinfo.equips) for (let itf of equip.interfaces)
			nodesssidmap.set(itf.macaddress, itf.wifissid);

		let fillareas: Map<string, boolean> = new Map();
		for (let field of E5EntHMetWifi.fields) if (field.name === "airtime") fillareas.set(field.name, true);

		let plotlystack: Map<string, boolean> = new Map();
		for (let field of E5EntHMetWifi.fields) if (field.name === "airtime") plotlystack.set(field.name, true);

		for (let idx = 0; idx < 4; idx++) {
			xydata.left.push({ numdatas: undefined, categdata: undefined });
			xydata.right.push({ numdatas: undefined, categdata: undefined });
		}

		if (selectedinfo.type !== E5NetElementType.none) for (let side of sides) for (let idx = 0; idx < 4; idx++) {
			let shouldfill: boolean | undefined = fillareas.get(state[side + "source"][idx]);
			if (shouldfill) xydata[side][idx].options = { fill: true };

			let shouldstack: boolean | undefined = plotlystack.get(state[side + "source"][idx]);
			if (shouldstack) xydata[side][idx].options = { stacked: true };

			let field: E5HMetWifiField | null = state[side + "field"][idx];
			if (field !== null) {
				let fieldname: string = "", fieldoption: string = state[side + "fieldoption"][idx];
				fieldname = field.name + fieldoption;
				let groups: string[] = field.group ?? [""];

				if (field.categ !== true) {
					let numdatas: E5MetricNumData[] = [];
					for (let group of groups) {
						let numdata: E5MetricNumData = { xaxisdata: [], yaxisdata: [], datalabel: "" };

						// airtime colors
						if (E5HMetric.airtimeProps.includes(group)) {
							numdata.color = E5HMetric.getAirtimeChartColor(group);
						}

						numdatas.push(numdata);
						let gfieldname: string = fieldname + group;
						if (group === "") numdata.datalabel = E5UtilI18n._("wifih-metrics-name-" + fieldname);
						else numdata.datalabel = E5UtilI18n._("wifih-metrics-group" + group);

						let hashtime: any = {}, prevmet: E5EntHMetWifi | undefined = undefined;
						let sortedMetrics = [...metricinfo.metrics].sort((a, b) => a.time.unix() - b.time.unix())
						for (let met of sortedMetrics) {
							if (E5BandEnumIsEth(met.wifiband) || met.wifiband === E5BandEnum.none) continue;
							if (filteronparent) {
								if (selectedinterface !== met.parentinterfmac) continue;
								if (hashtime["" + met.time.unix()] !== undefined) continue;
								hashtime["" + met.time.unix()] = true;
							} else if ((selectedinfo.type !== E5NetElementType.device || met.devicemac !== selectedinfo.station.macaddress)
								&& (selectedinfo.type !== E5NetElementType.node || met.nodeimei !== selectedinfo.equip.imei))
								continue;

							if (prevmet === undefined) {
								numdata.xaxisdata.push('');
								numdata.yaxisdata.push(undefined);
							}

							// process cut = prev.end < cur.start - holesizesec
							if (prevmet !== undefined && prevmet.endtime.unix() < met.starttime.unix() -
								E5MainConfig.GetHoleSizeSec()) {
								numdata.xaxisdata.push(prevmet.endtime.format());
								numdata.yaxisdata.push((prevmet as any)[gfieldname]);
								numdata.xaxisdata.push('');
								numdata.yaxisdata.push(undefined);
								numdata.xaxisdata.push('');
								numdata.yaxisdata.push(undefined);
								numdata.xaxisdata.push(met.starttime.format());
								numdata.yaxisdata.push((met as any)[gfieldname]);
							}

							numdata.xaxisdata.push(met.time.format());
							numdata.yaxisdata.push((met as any)[gfieldname]);

							prevmet = met;
						}
					}
					xydata[side][idx].numdatas = numdatas;
				} else {
					let categdata: E5MetricCategData =
						{ xaxisdata: [], yaxisdata: [], datalabel: E5UtilI18n._("wifih-metrics-name-" + fieldname) };
					//console.log(selectedinfo,metricinfo)
					let hashtime: any = {}, prevmet: E5EntHMetWifi | undefined = undefined;
					for (let met of metricinfo.metrics) {
						if (E5BandEnumIsEth(met.wifiband) || met.wifiband === E5BandEnum.none) continue;
						if (filteronparent) {
							if (selectedinterface !== met.parentinterfmac) continue;
							if (hashtime["" + met.time.unix()] !== undefined) continue;
							hashtime["" + met.time.unix()] = true;
						} else if ((selectedinfo.type !== E5NetElementType.device || met.devicemac !== selectedinfo.station.macaddress) &&
							(selectedinfo.type !== E5NetElementType.node || met.nodeimei !== selectedinfo.equip.imei))
							continue;
						// process micro cut = prevend<curstart-5
						// if (prevmet !== undefined)
						// 	console.log(prevmet.endtime.unix() + ":" + met.starttime.unix() + "=" + (met.starttime.unix() - prevmet.endtime.unix()));
						if (prevmet !== undefined && prevmet.endtime.unix() < met.starttime.unix() - 2) {
							//console.log(prevmet.endtime.unix() + ":" + met.starttime.unix() + "=" + (met.starttime.unix() - prevmet.endtime.unix()));
							categdata.xaxisdata.push(prevmet.endtime.format());
							categdata.yaxisdata.push(undefined);
							categdata.xaxisdata.push(met.starttime.format());
							categdata.yaxisdata.push(undefined);
						}
						if (fieldname === "wifi") {
							categdata.xaxisdata.push(met.starttime.format());
							categdata.yaxisdata.push(met.wifiband.substr(0, 1) + "G-ch" + met.wifichannel);
							categdata.xaxisdata.push(met.endtime.format());
							categdata.yaxisdata.push(met.wifiband.substr(0, 1) + "G-ch" + met.wifichannel);
						} else if (fieldname === "parentinterfmac") {
							let ifip: string = met.parentinterfmac, ifssid: string | undefined = nodesssidmap.get(ifip);
							if (ifssid !== undefined) ifip = ifssid + "/" + ifip;
							categdata.xaxisdata.push(met.starttime.format());
							categdata.yaxisdata.push(E5UtilI18n._("wifih-metrics-name-parentip") + " " + ifip);
							categdata.xaxisdata.push(met.endtime.format());
							categdata.yaxisdata.push(E5UtilI18n._("wifih-metrics-name-parentip") + " " + ifip);
						}
						prevmet = met;
					}
					xydata[side][idx].categdatas = [categdata];
				}
			}
		}
		st.xydata = xydata;
	}
});
