import React from "react";
import { observer } from "mobx-react";
import * as BP from "@blueprintjs/core";
import { IReactionDisposer, reaction } from "mobx";
//
import { E5StoreHMetWifi, E5StoreHEquips, E5StoreHTopoSelected, E5StoreH } from "../../../store/E5StoreH";
import { E5EntHMetWifi, E5HMetWifiField } from "../../../entity/household/metric/E5EntHMetWifi";
import { E5BandEnumIsEth, E5NetElementType } from "../../../entity/E5Enums";
import { E5XYChart, E5XYSource } from "../../../global/plot/E5XYChart";//eslint-disable-line
import { E5Store, E5StoreLangInfo } from "../../../store/E5Store";
import { E5PieChart } from "../../../global/plot/E5PieChart";
import { E5UtilI18n } from "../../../global/E5MainLang";
import { E5HMetricIndic } from "./E5HMetricIndic";
import { E5HEquipList } from "../E5HEquipList";
import { E5Time } from "../../../util/E5Time";//eslint-disable-line
import { E5HMetric } from "./E5HMetric";
//
import "./E5HMetricWifiEquip.css";
import { E5EntHStation } from "../../../entity/household/topo/E5EntHStation";
import { Select, OutlinedInput, MenuItem, InputLabel, FormControl } from '@mui/material'
import {E5Text} from "../../../util/E5Text";

//E5
interface E5HMetricWifiEquipState {
	selectedinterface: string;

	mindiceth?: number;
	mindicwifi?: number;

	leftsource: string[];
	leftfield: E5HMetWifiField[];
	leftfieldoption: string[];

	rightsource: string[];
	rightfield: E5HMetWifiField[];
	rightfieldoption: string[];

	xydata: any;

	pieids: string[];
	pieparents: string[];
	pievalues: number[];
	pielabels: string[];

	rssimin?: number;
	rssiavg?: number;
	rssimax?: number;

	airtimemin?: number;
	airtimeavg?: number;
	airtimemax?: number;

	noisemin?: number;
	noiseavg?: number;
	noisemax?: number;

	glitchmin?: number;
	glitchavg?: number;
	glitchmax?: number;

	uplinkmin?: number;
	uplinkavg?: number;
	uplinkmax?: number;

	downlinkmin?: number;
	downlinkavg?: number;
	downlinkmax?: number;
}

//E5
interface E5HMetricWifiEquipProps {
	langinfo: E5StoreLangInfo;
	equipinfo: E5StoreHEquips;
	selectedinfo: E5StoreHTopoSelected;
	metricinfo: E5StoreHMetWifi;
}

//E5
export const E5HMetricWifiEquip = observer(class E5HMetricWifiEquip extends React.PureComponent
	<E5HMetricWifiEquipProps, E5HMetricWifiEquipState> {

	// ---------------- MEMBERS ----------------

	//E5
	stop_change_selected_equip?: IReactionDisposer;
	stop_change_selected_interface?: IReactionDisposer;

	// ---------------- INIT ----------------

	//E5
	constructor(props: E5HMetricWifiEquipProps, state: E5HMetricWifiEquipState) {
		super(props, state);
		let { fields } = E5EntHMetWifi, udf: undefined = undefined;
		this.state = {
			selectedinterface: "",

			mindiceth: udf, mindicwifi: udf,

			leftsource: ["airtime", "lastratelink", "glitchrate", ""],
			leftfield: [fields[0], fields[7], fields[13], fields[0]], leftfieldoption: ["", "", "", ""],

			rightsource: ["wifi", "wifi", "wifi", "wifi"],
			rightfield: [fields[14], fields[14], fields[14], fields[14]], rightfieldoption: ["", "", "", ""],

			xydata: {
				left: [
					{ numdatas: udf, categdata: udf }, { numdatas: udf, categdata: udf },
					{ numdatas: udf, categdata: udf }, { numdatas: udf, categdata: udf }
				],
				right: [
					{ numdatas: udf, categdata: udf }, { numdatas: udf, categdata: udf },
					{ numdatas: udf, categdata: udf }, { numdatas: udf, categdata: udf }
				]
			},

			pieids: [], pieparents: [], pievalues: [], pielabels: [],

			rssimin: udf, rssiavg: udf, rssimax: udf, airtimemin: udf, airtimeavg: udf, airtimemax: udf,
			noisemin: udf, noiseavg: udf, noisemax: udf, glitchmin: udf, glitchavg: udf, glitchmax: udf,
			uplinkmin: udf, uplinkavg: udf, uplinkmax: udf, downlinkmin: udf, downlinkavg: udf, downlinkmax: udf
		};
	}

	//E5
	componentDidMount(): void {
		this.CheckNeedScroll();

		this.SetInterfBuildAll(this.props.equipinfo.equips.filter(eqp =>
			eqp.imei === this.props.selectedinfo.equip.imei)[0]?.interfaces[0].macaddress);
		this.stop_change_selected_equip =
			reaction(() => String(E5StoreH.Ins().metwifiinfo.loading) + this.props.selectedinfo.equip.imei,
				() => {
					this.CheckNeedScroll();

					this.SetInterfBuildAll(this.props.equipinfo.equips.filter(eqp =>
						eqp.imei === this.props.selectedinfo.equip.imei)[0].interfaces[0].macaddress);
				});
	}

	//E5
	componentWillUnmount(): void {
		this.stop_change_selected_equip?.();
		this.stop_change_selected_interface?.();
	}

	// ---------------- RENDER ----------------

	//E5
	render(): JSX.Element {
		// force rerender when lang changes
		let curlang = this.props.langinfo.curlang; //eslint-disable-line

		let { _ } = E5UtilI18n, { type, equip } = this.props.selectedinfo;
		let { node } = E5NetElementType, { PadNumberOrNA } = E5HMetricWifiEquip;

		let dig: number = 2, xytitle: string = _("wifih-metrics-nodecharttitle");
		if (type === node) xytitle += " (" + _("netelementtype-" + node) + " " + equip.imei + ")";

		return <div className="e5wifih-metrics-content e5columnfull e5wifi-metrics-equip">
			<div className="tabdesc">{E5UtilI18n._("wifih-metrics-wifi-eqp-tab-desc")}</div>
			<div className="e5wifih-metrics-components e5column-20">
				<div className="e5line-20">
					<div className="e5wifih-metrics-leftcolumn">
						<div className="e5wifih-metrics-householdelems">
							<E5HEquipList
								indicsysinfo={E5StoreH.Ins().indicsysinfo} langinfo={E5Store.Ins().langinfo}
								selectedinfo={E5StoreH.Ins().selectedinfo} showbox
								equipinfo={E5StoreH.Ins().equipinfo} minimal />
							<div className="e5wifih-metrics-interfacetitle">
								{E5UtilI18n._("wifih-metrics-wifi-eqp-tab-iftitle")}
							</div>
							<BP.RadioGroup
								className="e5wifih-metrics-nodeboxes" selectedValue={this.state.selectedinterface}
								onChange={e => this.SetInterfBuildAll(e.currentTarget.value)}>
								{this.RenderInterfaces()}
							</BP.RadioGroup>
						</div>
					</div>
					<div className="e5linefull e5column-20">
						<div className="e5wifih-metrics-nodes e5column-20">
							<E5HMetricIndic langinfo={E5Store.Ins().langinfo} avgeth={this.state.mindiceth}
								avgwifi={this.state.mindicwifi} elemtype={type} elemname={equip.imei}
								health={type === node ? equip.health : undefined} mode={'wifi'}>
								<FormControl className={"filterbloc"} sx={{ m: 1, width: 250 }}>
									<InputLabel id="name-label">{E5UtilI18n._("mainmenu-h-equipments")}</InputLabel>
									<Select labelId="name-label" onChange={this.handleChange} value={E5StoreH.Ins().selectedinfo.equip.imei} input={<OutlinedInput label={E5UtilI18n._("mainmenu-h-equipments")} />}>

										<MenuItem value={''}>
											{E5UtilI18n._("cb-eventlog-select-eqiupment")}
										</MenuItem>
										{E5StoreH.Ins().equipinfo.equips.map((equip) => {
											const equipmentType = equip.nodetype;
											let img = <div></div>
											if (equipmentType === 'ext') {
												img = <img src='./img/v3/metrics/extender.svg' className='e5wifih-metrics-nodes-select-svg' alt="Extender" />
											} else {
												img = <img src='./img/v3/metrics/gateway.svg' className='e5wifih-metrics-nodes-select-svg' alt="Gateway" />
											}
											return <MenuItem value={equip.imei} key={equip.imei}>
												{/* <img src='./img/v3/metrics/extender.svg' alt="Account unlocked" /> */}
												<div className='e5wifih-metrics-nodes-select-wrapper'>
													<div style={{ width: '30px', display: 'flex', justifyContent: "center" }}>
														{img}
													</div>
													{equip.imei}
												</div>

											</MenuItem>
										}
										)}
									</Select>
								</FormControl>
								<FormControl className={"filterbloc"} sx={{ m: 1, width: 250 }}>
									<InputLabel id="name-label">Interfaces</InputLabel>
									<Select labelId="name-label" onChange={e => this.SetInterfBuildAll(e.target.value)} value={this.state.selectedinterface} input={<OutlinedInput label="Interfaces" />}>
										<MenuItem value={''}>
											Select interface
										</MenuItem>
										{this.RenderInterfaces()}
									</Select>
								</FormControl>
							</E5HMetricIndic>
							<div className="e5column-20">
								<div className="e5line-20">
									<E5PieChart pieinfo={{
										title: _("wifih-metrics-pietitle"), loading: this.props.metricinfo.loading,
										labelisincident: false, valueisseconds: true, labels: this.state.pielabels,
										width: 456, ids: this.state.pieids, parents: this.state.pieparents,
										values: this.state.pievalues
									}} withNewComponent chartOption={{ type: 'sunburst' }} />
									<div className="e5wifih-metrics-nodes-summary-table">
										<div className="e5column-0">
											<div className="top-left-corner">{_("wifih-metrics-eqp-summary-table")}</div>
											<div>{_("wifih-metrics-eqp-summary-table-rssi")}</div>
											<div>{_("wifih-metrics-eqp-summary-table-airtime")}</div>
											<div>{_("wifih-metrics-eqp-summary-table-noise")}</div>
											<div>{_("wifih-metrics-eqp-summary-table-glitchrate")}</div>
											<div>{_("wifih-metrics-eqp-summary-table-uplinkrate")}</div>
											<div>{_("wifih-metrics-eqp-summary-table-downlinkrate")}</div>
										</div>
										<div className="e5column-0">
											<div>{_("wifih-metrics-eqp-summary-table-min")}</div>
											<div>{PadNumberOrNA(this.state.rssimin, 0)}</div>
											<div>{PadNumberOrNA(this.state.airtimemin, dig)}</div>
											<div>{PadNumberOrNA(this.state.noisemin, 0)}</div>
											<div>{PadNumberOrNA(this.state.glitchmin, dig)}</div>
											<div>{PadNumberOrNA(this.state.uplinkmin, dig)}</div>
											<div>{PadNumberOrNA(this.state.downlinkmin, dig)}</div>
										</div>
										<div className="e5column-0">
											<div>{_("wifih-metrics-eqp-summary-table-avg")}</div>
											<div>{PadNumberOrNA(this.state.rssiavg, 0)}</div>
											<div>{PadNumberOrNA(this.state.airtimeavg, dig)}</div>
											<div>{PadNumberOrNA(this.state.noiseavg, 0)}</div>
											<div>{PadNumberOrNA(this.state.glitchavg, dig)}</div>
											<div>{PadNumberOrNA(this.state.uplinkavg, dig)}</div>
											<div>{PadNumberOrNA(this.state.downlinkavg, dig)}</div>
										</div>
										<div className="e5column-0">
											<div>{_("wifih-metrics-eqp-summary-table-max")}</div>
											<div>{PadNumberOrNA(this.state.rssimax, 0)}</div>
											<div>{PadNumberOrNA(this.state.airtimemax, dig)}</div>
											<div>{PadNumberOrNA(this.state.noisemax, 0)}</div>
											<div>{PadNumberOrNA(this.state.glitchmax, dig)}</div>
											<div>{PadNumberOrNA(this.state.uplinkmax, dig)}</div>
											<div>{PadNumberOrNA(this.state.downlinkmax, dig)}</div>
										</div>
									</div>
								</div>
								<div className="e5compo">
									<div className="e5compotitle">{xytitle}</div>
									<div>{_("wifih-metrics-chartsubtitle")}</div>
									{this.RenderChart(0)}{this.RenderChart(1)}{this.RenderChart(2)}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>;
	}

	//E5
	RenderInterfaces = (): JSX.Element[] => {
		let interflist: JSX.Element[] = [];
		for (let equip of this.props.equipinfo.equips) if (equip.imei === this.props.selectedinfo.equip.imei) {
			for (let ifc of equip.interfaces) {
				let ifstr: string = ifc.wifissid !== "" ? ifc.wifissid : "";
				if (ifc.band !== "")
					if (ifstr.length > 0) ifstr += " (" + ifc.band + ")";
					else ifstr += ifc.band;
				interflist.push(<MenuItem
					value={ifc.macaddress} key={ifc.macaddress} disabled={E5BandEnumIsEth(ifc.band)}>
					{ifstr}
				</MenuItem>);
			}
			break;
		}
		return interflist;
	};

	//E5
	RenderChart = (idx: number): JSX.Element => <div>
		<E5XYChart withFullDayXAxis loading={this.props.metricinfo.loading}
			height={200} title={`${E5UtilI18n._("wifih-metrics-name-" + this.state.leftfield[idx].name)}
		
	 [${this.state.leftfield[idx].unit}]`}
			leftsource={this.state.xydata.left[idx]} rightsource={{}} withNewComponent chartOption={{ withoutSymbol: true, logarithmic: idx !== 3 }} />
	</div>;

	// ---------------- EVENTS ----------------

	//E5
	SetInterfBuildAll = (selectedinterface: string): void => {
		this.setState({ selectedinterface }, () => this.BuildAll());
	}

	// ---------------- UTILS ----------------

	handleChange = (e: any) => {
		const { value } = e.target
		const eqp = E5StoreH.Ins().equipinfo.equips.find(equip => equip.imei === value)
		if (eqp) {
			E5StoreH.Ins().ChangeSelectedElem(E5NetElementType.node, eqp, new E5EntHStation());
		}
	}

	//E5
	CheckNeedScroll = (): void => {
		let content: any = document.getElementsByClassName("e5wifih-metrics-content");
		if (content !== undefined && content !== null && content.length > 0) {
			let inner: any = document.getElementsByClassName("e5wifih-metrics-leftcolumn");
			if (inner !== undefined && inner !== null && inner.length > 0) {
				let innerheight: number = content[0].clientHeight - 26;
				inner[0].style.height = innerheight + "px";
				if (this.props.equipinfo.equips.length * 47 + 230 > innerheight)
					inner[0].className = "e5wifih-metrics-leftcolumn scroll-less-wide";
				else inner[0].className = "e5wifih-metrics-leftcolumn";
			}

			inner = document.getElementsByClassName("e5metrics-scrollable");
			if (inner !== undefined && inner !== null && inner.length > 0)
				inner[0].style.height = (content[0].clientHeight - 90) + "px";
		}
	};

	//E5
	static PadNumberOrNA: (val: number | undefined, digits: number) => string =
		(val: number | undefined, digits: number): string => val === undefined ? "N/A" : val.toFixed(digits);

	//E5
	BuildAll(): void {
		let st: Partial<E5HMetricWifiEquipState> = {}, udf: undefined = undefined;
		let { selectedinfo, metricinfo } = this.props, { selectedinterface } = this.state;
		if (selectedinterface !== "")
			E5HMetric.BuildGraphData(true, this.state, selectedinterface, selectedinfo,
				this.props.equipinfo, metricinfo, st);
		else
			st.xydata = {
				left: [
					{ numdatas: udf, categdata: udf }, { numdatas: udf, categdata: udf },
					{ numdatas: udf, categdata: udf }, { numdatas: udf, categdata: udf }
				],
				right: [
					{ numdatas: udf, categdata: udf }, { numdatas: udf, categdata: udf },
					{ numdatas: udf, categdata: udf }, { numdatas: udf, categdata: udf }
				]
			};
		this.BuildSummaryData(st);
		E5HMetric.BuildAverageData(true, selectedinterface, selectedinfo, metricinfo, st);
		E5HMetric.BuildPieData(true, selectedinterface, selectedinfo, metricinfo, st);
		this.setState(st as E5HMetricWifiEquipState);
	}

	//E5
	BuildSummaryData(st: Partial<E5HMetricWifiEquipState>): void {
		let idx: number, met: E5EntHMetWifi, udf: undefined = undefined;
		let cntrssi: number = 0, cntairtime: number = 0, cntnoise: number = 0;
		let cntglitch: number = 0, cntuplink: number = 0, cntdownlink: number = 0;

		st.rssimin = st.rssiavg = st.rssimax = st.airtimemin = st.airtimeavg = st.airtimemax = udf;
		st.noisemin = st.noiseavg = st.noisemax = st.glitchmin = st.glitchavg = st.glitchmax = udf;
		st.uplinkmin = st.uplinkavg = st.uplinkmax = st.downlinkmin = st.downlinkavg = st.downlinkmax = udf;

		for (idx = 0; idx < this.props.metricinfo.metrics.length; idx++) {
			met = this.props.metricinfo.metrics[idx];
			if (this.state.selectedinterface !== met.parentinterfmac) continue;

			// rssi
			if (met.rssi_min !== udf && (st.rssimin === udf || st.rssimin > met.rssi_min)) st.rssimin = met.rssi_min;
			if (met.rssi_avg !== udf && met.duration > 0) {
				if (st.rssiavg === udf) st.rssiavg = 0;
				st.rssiavg += met.rssi_avg * met.duration;
				cntrssi += met.duration;
			}
			if (met.rssi_max !== udf && (st.rssimax === udf || st.rssimax < met.rssi_max)) st.rssimax = met.rssi_max;

			// airtime
			if (met.airtime_interference !== udf && met.airtime_recvother !== udf && met.airtime_recvself !== udf &&
				met.airtime_sent !== udf) {
				let value: number = 100 *
					(met.airtime_interference + met.airtime_recvother + met.airtime_recvself + met.airtime_sent) / 255;
				if (st.airtimemin === udf || st.airtimemin > value) st.airtimemin = value;
				if (met.duration > 0) {
					if (st.airtimeavg === udf) st.airtimeavg = 0;
					st.airtimeavg += value * met.duration;
					cntairtime += met.duration;
				}
				if (st.airtimemax === udf || st.airtimemax < value) st.airtimemax = value;
			}

			// noise
			if (met.noise_min !== udf && (st.noisemin === udf || st.noisemin > met.noise_min))
				st.noisemin = met.noise_min;
			if (met.noise_avg !== udf && met.duration > 0) {
				if (st.noiseavg === udf) st.noiseavg = 0;
				st.noiseavg += met.noise_avg * met.duration;
				cntnoise += met.duration;
			}
			if (met.noise_max !== udf && (st.noisemax === udf || st.noisemax < met.noise_max))
				st.noisemax = met.noise_max;

			// glitch
			if (met.glitchrate !== udf) {
				if (st.glitchmin === udf || st.glitchmin > met.glitchrate) st.glitchmin = met.glitchrate;
				if (met.duration > 0) {
					if (st.glitchavg === udf) st.glitchavg = 0;
					st.glitchavg += met.glitchrate * met.duration;
					cntglitch += met.duration;
				}
				if (st.glitchmax === udf || st.glitchmax < met.glitchrate) st.glitchmax = met.glitchrate;
			}

			// uplink
			if (met.lastratelink_up_min !== udf && (st.uplinkmin === udf || st.uplinkmin > met.lastratelink_up_min))
				st.uplinkmin = met.lastratelink_up_min;
			if (met.lastratelink_up_avg !== udf && met.duration > 0) {
				if (st.uplinkavg === udf) st.uplinkavg = 0;
				st.uplinkavg += met.lastratelink_up_avg * met.duration;
				cntuplink += met.duration;
			}
			if (met.lastratelink_up_max !== udf && (st.uplinkmax === udf || st.uplinkmax < met.lastratelink_up_max))
				st.uplinkmax = met.lastratelink_up_max;

			// downlink
			if (met.lastratelink_down_min !== udf
				&& (st.downlinkmin === udf || st.downlinkmin > met.lastratelink_down_min))
				st.downlinkmin = met.lastratelink_down_min;
			if (met.lastratelink_down_avg !== udf && met.duration > 0) {
				if (st.downlinkavg === udf) st.downlinkavg = 0;
				st.downlinkavg += met.lastratelink_down_avg * met.duration;
				cntdownlink += met.duration;
			}
			if (met.lastratelink_down_max !== udf
				&& (st.downlinkmax === udf || st.downlinkmax < met.lastratelink_down_max))
				st.downlinkmax = met.lastratelink_down_max;
		}

		if (cntrssi > 0 && st.rssiavg !== udf) st.rssiavg = E5Text.RoundUp(st.rssiavg / cntrssi);
		if (cntairtime > 0 && st.airtimeavg !== udf) st.airtimeavg = E5Text.RoundUp(st.airtimeavg / cntairtime);
		if (cntnoise > 0 && st.noiseavg !== udf) st.noiseavg = E5Text.RoundUp(st.noiseavg / cntnoise);
		if (cntglitch > 0 && st.glitchavg !== udf) st.glitchavg = E5Text.RoundUp(st.glitchavg / cntglitch);
		if (cntuplink > 0 && st.uplinkavg !== udf) st.uplinkavg = E5Text.RoundUp(st.uplinkavg / cntuplink);
		if (cntdownlink > 0 && st.downlinkavg !== udf) st.downlinkavg = E5Text.RoundUp(st.downlinkavg / cntdownlink);
	}
});
