import React, { useCallback, useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react";
import moment, { Moment } from "moment";
import * as BP from "@blueprintjs/core";
import Plot, { PlotParams } from "react-plotly.js";
import { PlotData, PlotMouseEvent } from "plotly.js";
//
import { E5Store } from "../../store/E5Store";
import { E5Time } from "../../util/E5Time";
//
import "./E5XYChart.css";
import ReactECharts from 'echarts-for-react';
import { E5UtilI18n } from "../E5MainLang";
//E5
export interface E5XYData {
	xaxisdata: (number | string)[];
	datalabel?: string;
	text?: string[];
	hovertemplate?: string;
	fillcolor?: string;
	dotted?: boolean;
	stairs?: boolean;
	stackgroup?: string;
	hidelegend?: boolean;
	transparent?: boolean;
	color?: string;
	tooltip?: string[];
}

//E5
export interface E5XYNumData extends E5XYData {
	yaxisdata: (number | string | undefined)[];
}

//E5
export interface E5XYCategData extends E5XYData {
	//yaxisdata: string[][];//y2levels
	yaxisdata: (string | undefined)[];
}

//E5
export interface E5XYOptions {
	fill?: boolean;
	stacked?: boolean;
	percent?: boolean;
	yistime?: boolean;
	rangemode?: "tozero";
	xtickvals?: string[];
	ytickvals?: string[];
	yticktext?: string[];
	bar?: boolean;
	barstack?: boolean;
	markers?: boolean;
}

//E5
export interface E5XYTimeOption {
	xisdaytime: boolean; // when x is daytime, xdata must be moment.format() = "2014-09-08T08:02:17-05:00" (ISO 8601)
	xisday: boolean; // when x is day, xdata must be "YYYY-MM-DD"
	xtimezone: number;
	holesizesec: number;
}

//E5
export interface E5XYSource {
	numdatas?: E5XYNumData[];
	categdatas?: E5XYCategData[];
	options?: E5XYOptions;
}


export interface E5XYChartOption {
	type?: string;
	filled?: boolean;
	colors?: string[];
	selected?: any;
	withoutSymbol?: boolean;
	logarithmic?: boolean;
	longLegendList?: boolean;
	customBar?: boolean;
	withoutLegend?: boolean;
	xAxis?: any;
	yAxis?: any;
	barNoStack?: boolean;
	newColorScheme?: boolean;
	legendScroll?: boolean;
	gridPorcent?: string;
	tollboxPorcentRight?: string;
	legendRight?: number | null,
	legendTop?: number | null,
	legendOrient?: string,
}

//E5
export type E5XYChartClickCB = (xval: string, name: string) => void;

//E5
// interface E5XYChartState {}

//E5
interface E5XYChartProps {
	leftsource: E5XYSource;
	rightsource: E5XYSource;
	xoptions?: E5XYTimeOption;
	hidelegend?: boolean;
	title?: string;
	subtitle?: string;
	subtitlenormal?: string | null;
	subtitlebold?: string;
	subtitlebelow?: string;
	sublegend?: JSX.Element;
	loading: boolean;
	height: number;
	width?: number;
	fullMargin?: string;
	helpcontent?: JSX.Element;
	topcontent?: JSX.Element;
	clickcb?: E5XYChartClickCB;
	formatterTooltip?: (params: any) => string;
	normaltraceorder?: boolean;
	chartOption?: E5XYChartOption;
	withNewComponent?: boolean;
	topLeftContent?: JSX.Element;
	week?: boolean,
	callback?: Function,
	additionalInfo?: Function;
	baseYaxis?: boolean;
	withFullDayXAxis?: boolean | null;
}

const NEW_COLOR_SCHEME = ['#EA615D', '#F27573', '#FF8D69', '#FFB951', '#FFD553', '#FFF179', '#DDE779', '#B0D684', '#85C988', '#69B36C'];

const COLOR_LIST = [
	'#43A047', '#66BB6A', '#9CCC65', '#D4E157', '#FFEE58', '#FFCA28', '#FFA726', '#FF7043', '#EF5350', '#E53935',
	'#5470c6', '#92cc76', '#fbd781', '#ef6767', '#73c0de', '#3ba271', '#fc8453', '#9a5fb4', '#eb7bcc', '#5470c6', '#92cc76', '#fac858'
];

export const DEFAULT_CHART_COLORS = ['#5470c6', '#91cc75', '#fac858', '#ee6666', '#73c0de', '#3ba272', '#fc8452', '#9a60b4', '#ea7ccc'];

function getMinAndMaxXAxisDateForTimeChart(dateString: string) {
	if (!dateString) {
		return
	}
	// Parse the date string
	const date = new Date(dateString);

	// Extract the year, month, and day
	const year = date.getFullYear();
	const month = String(date.getMonth() + 1).padStart(2, '0');
	const day = String(date.getDate()).padStart(2, '0');

	// Extract the GMT offset
	const gmtOffset = dateString.slice(-6); // Get the last 6 characters representing the GMT offset

	// Construct the date string in the format 'YYYY-MM-DD'
	const formattedDate = `${year}-${month}-${day}`;
	// min: '2024-03-04T00:00:00+01:00', example format
	// max: '2024-03-04T23:59:59', example format
	const startDate = `${formattedDate}T00:00:00${gmtOffset}`;
	const endDate = `${formattedDate}T23:59:59`;;

	return [startDate, endDate];
};


export const E5XYChart: React.FC<E5XYChartProps> = observer((props: E5XYChartProps) => {

	const [data, setData] = useState<PlotData[]>([])
	const [seriesState, setSeries] = useState([]);
	const [isLinear, setIsLinear] = useState(true)

	const GetData = useCallback((src: E5XYSource, isright: boolean): void => {
		let shape: string = src?.numdatas !== undefined ? "linear" : src?.categdatas !== undefined ? "hv" : "";
		let propsdatas: E5XYNumData[] | E5XYCategData[] = src?.numdatas ?? src?.categdatas ?? [];

		const datas: PlotData[] = propsdatas.map((propdata, idx) => {
			let data: any;
			data = {
				x: JSON.parse(JSON.stringify(propdata.xaxisdata)), y: JSON.parse(JSON.stringify(propdata.yaxisdata)),
				name: propdata.datalabel ?? "", text: propdata.text ?? "", hoverlabel: { font: { family: "montserrat" } },
				line: { shape }, hovertemplate: propdata.hovertemplate ?? "%{y} (%{x})<extra>%{fullData.name}</extra>"
			};

			if (propdata.fillcolor !== undefined) {
				data.marker = { color: propdata.fillcolor };
				data.fillcolor = propdata.fillcolor + "66";
			}

			if (propdata.dotted) data.line = { dash: "dot", width: 2 };
			if (propdata.stairs) data.line = { shape: "hv" };
			if (propdata.hidelegend) data.showlegend = false;
			if (propdata.transparent) {
				data.marker = { color: "transparent" };
				data.hovertemplate = undefined;
				data.hoverinfo = "skip";
			}

			if (src.options?.stacked) {
				data.stackgroup = "e5stackgroup";
				if (src.options.percent) data.groupnorm = "percent";
				if (propdata.stackgroup !== undefined) data.stackgroup = propdata.stackgroup;
			}

			if (src.options?.stacked === false && src.options.fill) {
				if (idx > 0) data.fill = "tonexty";
				else {// imperfect yet helpful workaround to improve filled areas when data has gaps
					data.fill = "tozeroy";
					data.fillcolor = "transparent";
				}
			}

			if (src.options?.fill === false) data.fill = "none";

			if (src.options?.bar) data.type = "bar";
			if (src.options?.markers) data.mode = "lines+markers";

			if (isright) data.yaxis = "y2";


			if (props.xoptions?.xisdaytime && data.x.length > 1) {
				// find start and end of day
				let start: Moment = moment(data.x[Math.round(data.x.length / 2)]);// timezone already set by caller
				start.utcOffset(E5Time.h_tzoffset);// always absolutety necessary
				start.startOf("day");
				let end: Moment = moment(start);
				end.utcOffset(E5Time.h_tzoffset);// always absolutety necessary
				end.endOf("day");
				// add undefined at start and end of the day
				data.x.splice(0, 0, start.format());
				data.x.push(end.format());
				// y2levels
				// if (iscateg) {
				// 	data.y[0].splice(0, 0, undefined);
				// 	data.y[1].splice(0, 0, undefined);
				// 	data.y[0].push(undefined);
				// 	data.y[1].push(undefined);
				// } else {
				// 	data.y.splice(0, 0, undefined);
				// 	data.y.push(undefined);
				// }
				data.y.splice(0, 0, undefined);
				data.y.push(undefined);
				// add undefined in holes
				let lastx: Moment = moment(data.x[data.x.length - 1]), newx: Moment;
				lastx.utcOffset(E5Time.h_tzoffset);// always absolutety necessary
				for (let idxd = data.x.length - 2; idxd >= 0; idxd--) {
					newx = moment(data.x[idxd]);
					if (lastx.unix() - newx.unix() > props.xoptions.holesizesec) {
						// add an undefined an the end of the hole
						let addx: Moment = moment(lastx);
						addx.utcOffset(E5Time.h_tzoffset);// always absolutety necessary
						addx.add(-1, "second");
						data.x.splice(idxd + 1, 0, addx.format());
						// y2levels
						// if (iscateg) {
						// 	data.y[0].splice(idxd + 1, 0, undefined);
						// 	data.y[1].splice(idxd + 1, 0, undefined);
						// } else
						// 	data.y.splice(idxd + 1, 0, undefined);
						data.y.splice(idxd + 1, 0, undefined);
						// add an undefined an the start of the hole
						addx = moment(newx);
						addx.utcOffset(E5Time.h_tzoffset);// always absolutety necessary
						addx.add(1, "second");
						data.x.splice(idxd + 1, 0, addx.format());
						// y2levels
						// if (iscateg) {
						// 	data.y[0].splice(idxd + 1, 0, undefined);
						// 	data.y[1].splice(idxd + 1, 0, undefined);
						// } else
						// 	data.y.splice(idxd + 1, 0, undefined);
						data.y.splice(idxd + 1, 0, undefined);
					}
					lastx = newx;
				}
			}
			return data
		})
		setData((prev: any) => isright ? [...prev, ...datas] : [...datas])
	}, [props?.xoptions]);

	// force rerender when lang changes
	let curlang = E5Store.Ins().langinfo.curlang; //eslint-disable-line
	useEffect(() => {
		GetData(props.leftsource, false);
		GetData(props.rightsource, true);
	}, [props.leftsource, props.rightsource, GetData, props.loading])
	useEffect(() => {
		if (props.callback) {
			const colors: { name: string, color: string }[] = [];
			seriesState.forEach((item: any) => {
				colors.push({ name: item.datalabel, color: item.itemStyle.color });
			});
			props.callback(colors)
		}
	}, [seriesState])

	const handleClick = useCallback((event: PlotMouseEvent): void => {
		if (event.points.length > 0) {
			let { x, data } = event.points[0];
			props.clickcb?.(String(x), data.name);
		}
	}, [props])

	const graphdata: PlotParams = useMemo(() => ({
		data: data,
		layout: {
			xaxis: {
				fixedrange: false, automargin: true, nticks: 5, tickvals: props.leftsource?.options?.xtickvals,
				tickmode: props.leftsource?.options?.xtickvals !== undefined ? "array" : "auto",
				tickformat: props.xoptions?.xisdaytime ? "%H:%M" : props.xoptions?.xisday ? "%a %e %b" : ""
			},
			yaxis: {
				tickformat: props.leftsource?.options?.yistime ? "%-Hh %-Mm %-Ss" : "",
				rangemode: props.leftsource?.options?.rangemode, range: props.leftsource?.options?.ytickvals,// firefox bug fix (bars not showing)
				fixedrange: true, automargin: true, tickvals: props.leftsource?.options?.ytickvals, ticktext: props.leftsource?.options?.yticktext,
				tickmode: props.leftsource?.options?.ytickvals !== undefined && props.leftsource?.options.yticktext !== undefined ? "array" : "auto",
				categoryorder: props.leftsource?.categdatas !== undefined ? "category ascending" : "trace"
			},
			yaxis2: {
				fixedrange: true, automargin: true, side: "right", overlaying: "y",
				categoryorder: props.rightsource?.categdatas !== undefined ? "category ascending" : "trace"
			},
			barmode: props.leftsource?.options?.barstack && props.leftsource?.options?.bar ? "stack" : undefined, font: { family: "montserrat" },
			height: props.height, width: props.width, margin: props.fullMargin ? props.fullMargin : { t: 30, r: 30, l: 30, b: 30 },
			legend: { orientation: "h", traceorder: props.normaltraceorder === true ? "normal" : undefined },
			showlegend: props.hidelegend !== true, hovermode: !!props.clickcb ? "closest" : undefined,
			paper_bgcolor: "#00000000", plot_bgcolor: "#00000000"
		},
		onClick: !!props.clickcb ? handleClick : undefined,
		config: { displayModeBar: false }
	}), [data, props, handleClick]);

	const getValueColor = (item: any, chart: E5XYChartOption | undefined, colors: Array<string>, index: number) => {
		if (chart?.filled) {
			return colors[index];
		} else {
			if (item.color) {
				return item.color;
			}
			return chart?.colors?.[index] ?? DEFAULT_CHART_COLORS[index % 9];
		}
	}

	const options = useMemo(() => {
		const data = props.leftsource?.numdatas || props.leftsource?.categdatas

		const selected: any = {};
		if (props.chartOption?.selected) {
			if (props.chartOption?.longLegendList) {
				data?.map((item: any) => item.datalabel)
					.forEach((label: string) => {
						selected[label] = props.chartOption?.selected.includes(label)
					})
			} else {
				data?.map((item: any) => item.datalabel)
					.filter((label: string) => label.includes(props.chartOption?.selected))
					.forEach((label: string) => {
						selected[label] = false
					})
			}

		}

		const yAxis = props.chartOption?.yAxis || props.chartOption?.customBar ?
			{
				type: 'value',
				show: false,
				interval: 2,
				max: 2,
				axisLine: {
					show: false
				}
			} :
			{
				type: props.leftsource?.categdatas ? 'category' : !isLinear ? 'log' : 'value'
			}
		let startDate;
		let endDate;
		const isNumdatasDate = (props.withFullDayXAxis && props.leftsource?.numdatas &&
			props.leftsource?.numdatas[0] &&
			props.leftsource?.numdatas[0].xaxisdata &&
			props.leftsource?.numdatas[0].xaxisdata[0]) ? true : false;
		const isCategoryDate = (props.withFullDayXAxis && props.leftsource?.categdatas &&
			props.leftsource?.categdatas[0] &&
			props.leftsource?.categdatas[0].xaxisdata &&
			props.leftsource?.categdatas[0].xaxisdata[0]) ? true : false;

		if (isNumdatasDate || isCategoryDate) {
			if (isNumdatasDate) {
				//@ts-ignore - need to update global ts config for this case during future refactoring
				[startDate, endDate] = getMinAndMaxXAxisDateForTimeChart(props.leftsource?.numdatas[0].xaxisdata[0] as string);
				// return;
			} else {
				//@ts-ignore - need to update global ts config for this case during future refactoring
				[startDate, endDate] = getMinAndMaxXAxisDateForTimeChart(props.leftsource?.categdatas[0].xaxisdata[0] as string);

			}

		}

		const xAxis = props.chartOption?.xAxis || {
			type: 'time',
			boundaryGap: false,
		}

		if (props.withFullDayXAxis && startDate && endDate) {
			xAxis['min'] = startDate;
			xAxis['max'] = endDate;
			xAxis['interval'] = 3600 * 1000; // 1 hour in milliseconds
			xAxis['splitNumber'] = 7; // Number of intervals to display
		}

		const legend = props.chartOption?.longLegendList ? {
			show: !props.chartOption?.withoutLegend,
			bottom: 0,
			selected,
			data: [...Object.keys(selected).filter(key => selected[key]), ...Object.keys(selected).filter(key => !selected[key]).sort()],
			type: 'scroll',
			orient: 'vertical',
			pageIconSize: 10,
			right: 0,
			top: 40
		} : {
			show: !props.chartOption?.withoutLegend,
			bottom: 0,
			right: props.chartOption?.legendRight || null,
			top: props.chartOption?.legendTop || null,
			orient: props.chartOption?.legendOrient || 'horizontal',
			selected,
		}

		if (props.chartOption?.legendScroll) {
			legend['type'] = 'scroll';
		}

		let colors: any[];

		if (props.chartOption?.newColorScheme) {
			colors = NEW_COLOR_SCHEME;
		} else {
			colors = props.chartOption?.filled && data?.[0]?.datalabel?.includes('100') ? [...COLOR_LIST].reverse() : COLOR_LIST
		}

		const series: any = props.chartOption?.barNoStack ? [
			{
				type: 'bar',
				data: data?.map((item: any, index: number) => {
					return {

						value: item.yaxisdata[0],
						itemStyle: { color: '#E53935' },
					}
				})
			}] : data?.map((item: any, index: number) => {
				return {
					id: index,
					name: item.datalabel,
					type: props.chartOption?.type || 'line',
					stack: !props.chartOption?.type && props.baseYaxis ? null : 'Total',
					smooth: props.chartOption?.filled,
					lineStyle: props.chartOption?.filled ? { width: 0 } : null,
					showSymbol: (props.chartOption?.filled || props.chartOption?.withoutSymbol) ? false : true,
					areaStyle: props.chartOption?.filled ? { opacity: 0.8 } : null,
					itemStyle: { color: getValueColor(item, props.chartOption, colors, index) },
					emphasis: {
						focus: 'series'
					},
					data: item.xaxisdata.map((value: string, index: number) => {
						let values;
						if (item?.tooltip) {
							values = [value, item.yaxisdata[index], item?.tooltip[index]]
						} else {
							values = [value, item.yaxisdata[index]]
						}
						return {
							value: values,
						}
					}),
					datalabel: item.datalabel
				}
			});

		if (seriesState?.length < 2) {
			setSeries(series)
		}

		const option = {
			tooltip: {
				trigger: 'axis',
				axisPointer: {
					type: 'line',
					label: {
						backgroundColor: '#6a7985'
					}
				},
				formatter: props.formatterTooltip || undefined,
			},
			legend,
			toolbox: {
				right: props.chartOption?.tollboxPorcentRight || '10%',
				feature: {
					dataView: { 
						show: true,
						readOnly: true,
						title: E5UtilI18n._("toolbox-dataView"),
					},
					dataZoom: {
						yAxisIndex: 'none',
						title: {
							zoom: E5UtilI18n._("toolbox-datazoom-zoom"),
							back: E5UtilI18n._("toolbox-datazoom-back")
						},
					},
					magicType: !props.chartOption?.filled ? {
						show: true,
						type: ['line', 'bar'],
						title: {
							line: E5UtilI18n._("toolbox-magicType-line"),
							bar: E5UtilI18n._("toolbox-magicType-bar")
						},
						option: { bar: { stack: 'Add' }, line: { stack: '' } },
					} : null,
					restore: {
						show: true,
						title: E5UtilI18n._("toolbox-restore"),
					},
					saveAsImage: {
						title: E5UtilI18n._("toolbox-saveAsImage"),
					}
				}
			},
			grid: {
				left: '3%',
				right: props.chartOption?.longLegendList ? '17%' : props.chartOption?.gridPorcent || '4%',
				bottom: props.chartOption?.longLegendList ? '7%' : '15%',
				containLabel: true
			},
			xAxis: [
				xAxis
			],
			yAxis: [
				yAxis
			],
			series: series
		};
		if (props.week) {
			option.grid.bottom = '30%'
		};
		return option;
	}, [props, isLinear]);

	const [chartOptions, setChartOptions] = useState(options);
	const handleRestoreClick: React.FC<{}> = (): any => {
		setChartOptions(chartOptions);
	};

	// console.log(graphdata,props,options)	
	// e5column-5 solves many css issues, in particular width is not imposed by plotly
	// don't hide <Plot> when loading, because <Plot> fixes component height


	return <div className="e5compo e5xy-chart e5column-0">
		<div className="e5line-5">
			{props.topLeftContent && <div className='top-left-container'>{props.topLeftContent}</div>}

			{props.chartOption?.logarithmic &&
				<div className='switch-container'>
					<div className={isLinear ? 'button active' : 'button'} onClick={() => setIsLinear(true)}>Linear</div>
					<div className={!isLinear ? 'button active' : 'button'} onClick={() => setIsLinear(false)}>Logarithmic</div>
				</div>
			}
			{props.additionalInfo && <props.additionalInfo />}
			{props.title && <div className={props.chartOption?.logarithmic ? 'e5compotitle with-padding' : "e5compotitle"}>{props.title}
				{props.loading && <BP.Spinner className="e5spinwait" size={15} />}
			</div>}
			{props.subtitlenormal !== undefined && <div className="subtitlenormal">{props.subtitlenormal}</div>}
			{props.helpcontent !== undefined &&
				<BP.Popover content={props.helpcontent} position={BP.Position.BOTTOM_LEFT}
					children={<BP.Button icon="help" small={true} />} />}
			{/* {props.topcontent} */}
		</div>
		{props.subtitle !== undefined && <div>{props.subtitle}</div>}
		{props.subtitlebold !== undefined && <div><b>{props.subtitlebold}</b></div>}
		{props.withNewComponent ? <ReactECharts option={options} onEvents={{ 'restore': handleRestoreClick }} /> : <Plot {...graphdata} />}
		{props.sublegend !== undefined && props.sublegend}
		{props.subtitlebelow !== undefined && <div>{props.subtitlebelow}</div>}
	</div>;
});
